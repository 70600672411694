import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Container from '../../components/Container'
import ContactForm from '../../components/Forms/Contact'

export default ({ data: { site } }) => (
  <Layout site={site} noFooter>
    <Container>
      <ContactForm />
    </Container>
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
  }
`
